import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'

export const REQUEST_LISTING_POOL = 'REQUEST_LISTING_POOL'
export const REQUEST_LISTING_POOL_SUCCESS = 'REQUEST_LISTING_POOL_SUCCESS'
export const REQUEST_LISTING_POOL_FAILURE = 'REQUEST_LISTING_POOL_FAILURE'

const defaultState = {
  data: [],
  fetchingListing: true,
  failedFetchingListing: false,

}

const reducer = createReducer(defaultState, {
  [REQUEST_LISTING_POOL]: (state) => {
    return {
      ...state,
      fetchingListing: true,
      failedFetchingListing: false,
    }
  },
  [REQUEST_LISTING_POOL_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      fetchingListing: false,
      failedFetchingListing: false,
    }
  },
  [REQUEST_LISTING_POOL_FAILURE]: (state) => {
    return {
      ...state,
      fetchingListing: false,
      failedFetchingListing: true,
    }
  },

})

export default reducer
export const namespace = 'listingPool'

export const fetchListingPool = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/reward-pools/all-types',
    actionTypes: [
      REQUEST_LISTING_POOL,
      REQUEST_LISTING_POOL_SUCCESS,
      REQUEST_LISTING_POOL_FAILURE,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}

