import { all, fork, put, takeLatest } from 'redux-saga/effects'
import {
  REQUEST_LISTING_POOL_FAILURE,
  fetchListingPool
} from './index'
import { showToastAlert } from '../app'

function* initPage() {
  yield put(fetchListingPool())
}

function* listenEvents() {
  yield takeLatest( REQUEST_LISTING_POOL_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })
}

export default function* loadListingManagement() {
  yield all([fork(initPage), fork(listenEvents)])
}
