import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import appReducer, { namespace as appNamespace } from './modules/app'
import authReducer, { namespace as authNamespace } from './modules/auth'
import homeReducer, { namespace as homeNamespace } from './modules/home'
import userManagementReducer, {
  namespace as userManagementNamespace,
} from './modules/userManagement'
import createUserManagementReducer, {
  namespace as createUserManagementNamespace,
} from './modules/createUserManagement'
import forgotPasswordReducer, {
  namespace as forgotPasswordNamespace,
} from './modules/forgotPassword'
import userManagementUpdateReducer, {
  namespace as userManagementUpdateNamespace,
} from './modules/userManagementUpdate'
import detailListingReducer, { namespace as detailListingNamespace } from './modules/detailsListing'
import listingManagementReducer, {
  namespace as listingManagementNamespace,
} from './modules/listingManagement'
import oneSignalReducer, { namespace as oneSignalNamespace } from './modules/onesignal'
import notificationReducer, { namespace as notificationNamespace } from './modules/notification'
import reportedListingReducer, {
  namespace as reportedListingNamespace,
} from './modules/reportedListing'
import courseManagementReducer, {
  namespace as courseManagementNamespace,
} from './modules/courseManagement'
import courseManagementCreateReducer, {
  namespace as courseManagementCreateNamespace,
} from './modules/courseManagementCreate'
import detailReportedReducer, {
  namespace as detailReportedNamespace,
} from './modules/detailReported'
import courseManagementUpdateReducer, {
  namespace as courseManagementUpdateNamespace,
} from './modules/courseManagementUpdate'
import vendorManagementReducer, {
  namespace as vendorManagementNamespace,
} from './modules/vendorManagement'
import vendorManagementCreateReducer, {
  namespace as vendorManagementCreateNamespace,
} from './modules/vendorManagementCreate'
import vendorManagementUpdateReducer, {
  namespace as vendorManagementUpdateNamespace,
} from './modules/vendorManagementUpdate'
import userRegistrationReducer, {
  namespace as userRegistrationNamespace,
} from './modules/listRequestRegistration'

import userRegistrationUpdateReducer, {
  namespace as userRegistrationUpdateNamespace,
} from './modules/detailRegistration'
import listingPoolReducer, {
  namespace as listingPoolNamespace,
} from './modules/listPool'

const RootReducer = (extraReducers) =>
  combineReducers({
    [authNamespace]: authReducer,
    [appNamespace]: appReducer,
    form: formReducer,
    [homeNamespace]: homeReducer,
    [userManagementNamespace]: userManagementReducer,
    [createUserManagementNamespace]: createUserManagementReducer,
    [forgotPasswordNamespace]: forgotPasswordReducer,
    [userManagementUpdateNamespace]: userManagementUpdateReducer,
    [userManagementUpdateNamespace]: userManagementUpdateReducer,
    [detailListingNamespace]: detailListingReducer,
    [listingManagementNamespace]: listingManagementReducer,
    [listingManagementNamespace]: listingManagementReducer,
    [oneSignalNamespace]: oneSignalReducer,
    [notificationNamespace]: notificationReducer,
    [reportedListingNamespace]: reportedListingReducer,
    [detailReportedNamespace]: detailReportedReducer,
    [courseManagementNamespace]: courseManagementReducer,
    [courseManagementCreateNamespace]: courseManagementCreateReducer,
    [courseManagementUpdateNamespace]: courseManagementUpdateReducer,
    [vendorManagementNamespace]: vendorManagementReducer,
    [vendorManagementCreateNamespace]: vendorManagementCreateReducer,
    [vendorManagementUpdateNamespace]: vendorManagementUpdateReducer,
    [userRegistrationNamespace]: userRegistrationReducer,
    [userRegistrationUpdateNamespace]: userRegistrationUpdateReducer,
    [listingPoolNamespace]: listingPoolReducer,
    ...extraReducers,
  })
export default RootReducer
